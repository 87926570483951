<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getTeachers"
              @onEdit="onEdit"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <teachers-form
      v-if="selTeacher"
      :teacher="selTeacher"
      @close="selTeacher=null"
      @onSaved="$refs.vtable.init()"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import TeachersForm from './TeachersForm.vue';

export default {
  components: {
    VueTable,
    TeachersForm,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'user_id',
            mask: 'id',
            sortable: true,
          },
          {
            title: 'first_name',
            mask: 'First Name',
            sortable: true,
          },
          {
            title: 'last_name',
            mask: 'Last Name',
            sortable: true,
          },
          {
            title: 'classrooms',
            callback: (item) => item.length,
          },

        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Edit Teacher',
            callback: 'onEdit',
            icon: 'edit',
          },
        ],
        values: {},
      },
      selTeacher: null,
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getTeachers(params) {
      this.request(this.$API.TEACHERS.paginableUrl(params), 'get', null, ({ data }) => {
        const aux = data;
        aux.data.map((x) => {
          const a = x;
          a.classrooms.map((z) => {
            const b = z;
            b.name = `${b.classroom_code} ${b.classroom_name ? `| ${b.classroom_name.name}` : ''}`;
            return b;
          });
          return a;
        });
        this.vTable.values = data;
      });
    },
    onEdit(teacher) {
      this.selTeacher = teacher;
    },
  },
};
</script>

<style scoped>
</style>
